import styled from "styled-components";
import Calendar from "react-calendar";
import React from "react";

interface Props {
    date: Date;
    onChange: (date: Date, e: React.ChangeEvent<HTMLInputElement>) => void;
    style: React.CSSProperties;
}

export const StyledCalendar = (props: Props) => {

    const formatDate = (locale: string, date: Date) => {
        return `${date.getDate()}`;
    };

    return <CalendarStyle style={props.style}>
        <Calendar calendarType="US" formatDay={formatDate} onChange={props.onChange} value={props.date}/>
    </CalendarStyle>
};

const CalendarStyle = styled.div`
  .react-calendar__tile:nth-child(7n) {
    color: #0000ff;
  }

  .react-calendar__tile:nth-child(7n).react-calendar__tile--active {
    color: #fff;
  }

`
