import React from 'react';
import {AppProvider} from "../context/AppContext";
import {Spinner} from "./common/Spinner";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Forgot} from "./auth/Forgot";
import {Login} from "./auth/Login";
import {Reset} from "./auth/Reset";
import {Dashboard} from "./main/Dashboard";
import {Logout} from "./auth/Logout";
import {List} from "./main/List";
import {Graph} from "./main/Graph";
import {Setting} from "./main/Setting";

export const App = () => {

    return (
        <AppProvider>

            <Spinner/>

            <BrowserRouter>

                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/forgot" element={<Forgot/>}/>
                    <Route path="/reset" element={<Reset/>}/>
                    <Route path="/logout" element={<Logout/>}/>

                    <Route path="/" element={<Dashboard />}/>
                    <Route path="/list" element={<List />}/>
                    <Route path="/graph" element={<Graph />}/>
                    <Route path="/setting" element={<Setting />}/>

                </Routes>

            </BrowserRouter>


        </AppProvider>
    );
}
