import styled from "styled-components";
import icoSelect from "../../images/ico-select.svg";

export const BaseSelect = styled.select`
  appearance: none;
  height: 38px;
  font-size: 16px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
  padding: 0 34px 0 11px ;
  background-color: #fff;
  border: none;
  width: 200px;
  background-image: url(${icoSelect});
  background-repeat: no-repeat;
  background-position: top 50% right 10px;
  
  
`;

