import styled from "styled-components";
import icoSearch from "../../images/ico-search.svg";
import icoCalendar from "../../images/ico-calendar.svg"

export const BaseInput = styled.input`
  height: 38px;
  font-size: 16px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
  padding: 0 34px 0 11px ;
  background-color: #fff;
  border: none;
  width: 180px;
  background-image: url(${icoSearch});
  background-repeat: no-repeat;
  background-position: top 50% right 10px;
  
  &::placeholder {
    color: #838383;
    opacity: 1;
  }
  
  &.calendar {
    background-image: url(${icoCalendar});
    width: 130px;
    
  }
  
`;

