import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {Layout} from "./Layout";
import styled from "styled-components";
import {BaseInput} from "../common/BaseInput";
import {BaseSelect} from "../common/BaseSelect";
import {convertValue, CreateDateFromYmd, CreateYmdFromDate, SetPageTitle} from "../../utility/Utility";
import {GraphData, GraphMetaData, MimosysGraph} from "./graph/MimosysGraph";
import {useLocation, useNavigate} from "react-router-dom";
import {MimosysReportRequest, MimosysReportResponse} from "../../../reception-service/services/react-common/typings";
import {ApiGraphData} from "../../api/Api";
import {AppContext} from "../../context/AppContext";
import {Message} from "../common/Message";
import {StyledCalendar} from "../common/StyledCalendar";
import 'react-calendar/dist/Calendar.css';


export const Graph = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState<string>("");
    const [division, setDivision] = useState<string>("");
    const [graphType, setGraphType] = useState<string>("vitality");
    const [from, setFrom] = useState<Date>(new Date());
    const [to, setTo] = useState<Date>(new Date());
    const {setSpinner} = useContext(AppContext);
    const [message, setMessage] = useState<string | null>(null);
    const [graphData, setGraphData] = useState<GraphMetaData[]>([]);
    const [isCalendar, setIsCalendar] = useState<boolean>(false);
    const [calendarType, setCalendarType] = useState<string>("");
    const [calendarDt, setCalendarDt] = useState<Date>(new Date());
    const fromRef = useRef<HTMLInputElement>(null);
    const toRef = useRef<HTMLInputElement>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    document.title = SetPageTitle("グラフ");

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const now = new Date();

        const name = params.get("name") ?? "";
        const division = params.get("division") ?? "";
        const graphType = params.get("graphType") ?? "vitality";

        // デフォルトは今月
        const from = params.get("from") ? CreateDateFromYmd(params.get("from") ?? "") : new Date(now.getFullYear(), now.getMonth(), 1);
        const to = params.get("to") ? CreateDateFromYmd(params.get("to") ?? "") : new Date(now.getFullYear(), now.getMonth() + 1, 0);

        setName(name);
        setDivision(division);
        setFrom(from);
        setTo(to);
        setGraphType(graphType);
        getGraphData(from, to, name, division);

    }, [location]);

    // グラフデータの取得
    const getGraphData = (from: Date, to: Date, name: string, division: string): void => {

        setSpinner(true);

        const req: MimosysReportRequest = {
            from: CreateYmdFromDate(from),
            to: CreateYmdFromDate(to),
            name: name,
            division: division,
        };

        setIsLoaded(false);

        ApiGraphData(req)
            .then((res) => {

                const data = res.data as MimosysReportResponse;

                const graphData: GraphMetaData[] = [];

                // 日数計算
                const days = (to.getTime() - from.getTime()) / (86400 * 1000) + 1;

                data.reports.forEach((d) => {

                    // グラフデータのフィルタリング、歯抜け調整
                    const filterGraphData = () => {
                        const data: GraphData[] = [];
                        for (let i = 0; i < days; i++) {

                            // 日付の歯抜けをなくす
                            const ymd = CreateYmdFromDate(new Date(from.getTime() + (86400 * 1000 * i)));

                            const datum: GraphData = {
                                ymd: ymd,
                                values: [],
                            }

                            for (let a = 0; a < d.data.length; a++) {
                                if (ymd === d.data[a].ymd) {
                                    let value: number | null = null;
                                    switch (graphType) {
                                        case "vitality":
                                            value = convertValue(d.data[a].vitality);
                                            break;
                                        case "mental_activity":
                                            value = convertValue(d.data[a].mental_activity);
                                            break;
                                    }
                                    datum.values.push(value ?? 0);
                                }
                            }
                            data.push(datum);
                        }
                        return data;
                    };

                    const metaData: GraphMetaData = {
                        employee_id: d.employee_id,
                        label: d.label,
                        data: filterGraphData(),
                    };

                    console.log(graphData);

                    graphData.push(metaData);
                });

                setGraphData(graphData);

            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }
            })
            .finally(() => {
                setSpinner(false);
                setIsLoaded(true);
            })


    };

    // 検索フォーム送信ハンドラー
    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const params = new URLSearchParams();

        params.set("name", name);
        params.set("division", division);
        params.set("from", CreateYmdFromDate(from));
        params.set("to", CreateYmdFromDate(to));
        params.set("graphType", graphType);

        navigate(`/graph?${params.toString()}`);
    };

    // フォーム変更イベントハンドラー
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {

        e.preventDefault();

        switch (e.currentTarget.name) {
            case "name":
                setName(e.currentTarget.value);
                break;
            case "division":
                setDivision(e.currentTarget.value);
                break;
            case "graphType":
                setGraphType(e.currentTarget.value);
                break;
        }
    };

    // カレンダーの表示トグル
    const onCalendar = (e: React.MouseEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setCalendarType(e.currentTarget.name);
        setIsCalendar(true);

        if (e.currentTarget.name === "from") {
            setCalendarDt(from);
        } else {
            setCalendarDt(to);
        }
    };

    // カレンダーの表示位置プロパティを返す
    const getCalendarStyle = (): React.CSSProperties => {

        const style: React.CSSProperties = {
            position: "absolute",
            boxShadow: "0 2px 10px rgba(0, 0, 0, .3)",
            zIndex: 10000,
        };

        if (calendarType === "from" && fromRef.current) {
            const rect = fromRef.current.getBoundingClientRect();
            style.top = `${rect.top + rect.height + 10}px`;
            style.left = `${rect.left}px`;
        }

        if (calendarType === "to" && toRef.current) {
            const rect = toRef.current.getBoundingClientRect();
            style.top = `${rect.top + rect.height + 10}px`;
            style.left = `${rect.left}px`;
        }

        return style;
    };

    // カレンダー変更イベント
    const onChangeCalendar = (dt: Date, e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (calendarType === "from") {
            setFrom(dt);
        } else {
            setTo(dt);
        }

        setIsCalendar(false);
        setCalendarType("");
    };

    return <Layout>
        <StyledGraph>

            <div className="search">

                <form onSubmit={onSubmit}>

                    <BaseInput name="name" placeholder="氏名" value={name} onChange={onChange}/>
                    <BaseInput name="division" placeholder="部署" value={division} onChange={onChange}/>
                    <span>期間</span>
                    <BaseInput name="from" value={CreateYmdFromDate(from)} onClick={onCalendar} readOnly={true} className="calendar" ref={fromRef}/>
                    <span>〜</span>
                    <BaseInput name="to" value={CreateYmdFromDate(to)} onClick={onCalendar} readOnly={true} className="calendar" ref={toRef}/>

                    <BaseSelect name="graphType" value={graphType} onChange={onChange}>
                        <option value="vitality">元気圧</option>
                        <option value="mental_activity">心の活量値</option>
                    </BaseSelect>

                    <button className="btn-submit">検索</button>

                    {isCalendar && <StyledCalendar style={getCalendarStyle()} onChange={onChangeCalendar} date={calendarDt}/>}

                </form>
            </div>

            <Message message={message}/>

            {(isLoaded && graphData.length === 0) && <div className="no-report">ご指定の条件にマッチするグラフデータは登録されていません。条件を変えて検索してください。</div>}
            {(isLoaded && graphData.length >= 1) && <MimosysGraph data={graphData}/>}

        </StyledGraph>
    </Layout>
};

const StyledGraph = styled.div`
  .search {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    input, select {
      margin-right: 20px;
    }

    span {
      font-size: 16px;
      margin-right: 8px;
    }

    .btn-submit {
      border: none;
      height: 38px;
      color: #fff;
      background-color: #031F69;
      width: 105px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
      font-size: 16px;
      border-radius: 2px;
    }

  }
  
  // データなし
  .no-report {
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
    border-radius: 2px;
    padding: 20px;
    //max-width: 1200px;
    display: block;
    margin: auto;
    width: 100%;
    color: #aaa;
  }

`;