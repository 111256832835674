import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Layout} from "./Layout";
import styled from "styled-components";
import {BaseInput} from "../common/BaseInput";
import {convertValue, SetPageTitle} from "../../utility/Utility";
import icoDown from "../../images/ico-down.svg";
import icoSort from "../../images/ico-sort.svg";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {ApiPagingMimosysTrends} from "../../api/Api";
import {MimosysTrendData, MimosysTrendPagingRequest, MimosysTrendPagingResponse} from "../../typings";
import {AppContext} from "../../context/AppContext";
import {Message} from "../common/Message";
import icoMimosysUp from "../../images/ico-mimosys-up.svg";
import icoMimosysNormal from "../../images/ico-mimosys-normal.svg";
import icoMimosysDown from "../../images/ico-mimosys-down.svg";

export const List = () => {

    const {setSpinner, token} = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [inputLimit, setInputLimit] = useState<number>(20);
    const [page, setPage] = useState<number>(1);
    const [message, setMessage] = useState<string | null>(null);
    const [total, setTotal] = useState<number>(0);
    const [mimosysTrends, setMimosysTrends] = useState<MimosysTrendData[]>([]);
    const [limit, setLimit] = useState<number>(20);
    const [name, setName] = useState<string>("");
    const [division, setDivision] = useState<string>("");
    const [sort, setSort] = useState<string>("");
    const [direction, setDirection] = useState<string>("");

    document.title = SetPageTitle("一覧");

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const limit = params.get("limit") ?? "20";
        const page = params.get("page") ?? "1";
        const direction = params.get("direction") ?? "desc";
        const sort = params.get("sort") ?? "last_analyzed";

        setInputLimit(parseInt(limit));
        setLimit(parseInt(limit));
        setPage(parseInt(page));
        setSort(sort);
        setDirection(direction);

        // ページネーションデータ取得
        getPaging(parseInt(page), parseInt(limit), sort, direction);

    }, [location]);

    // ページネーションデータ取得
    const getPaging = (page: number, limit: number, sort: string, direction: string) => {

        setSpinner(true);
        setMessage(null);

        const req: MimosysTrendPagingRequest = {
            limit: limit,
            page: page,
            name: name,
            division: division,
            sort: sort,
            direction: direction,
        };

        ApiPagingMimosysTrends(req)
            .then((res) => {
                const data = res.data as MimosysTrendPagingResponse;

                setTotal(data.total);
                setMimosysTrends(data.mimosys_trends);

            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }
            })
            .finally(() => {
                setSpinner(false);
            });
    };

    // 件数変更
    const onChangeLimit = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setInputLimit(parseInt(e.currentTarget.value));
    };

    // ページネーション実行
    const onPaginationSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        navigate(createPageURL(1, inputLimit, sort, direction));
    };

    // ページのURLを作成
    const createPageURL = (page: number, limit: number, sort: string, direction: string): string => {
        const params = new URLSearchParams();
        params.set("page", String(page));
        params.set("limit", String(limit));
        params.set("name", name);
        params.set("division", division);
        params.set("sort", sort);
        params.set("direction", direction);

        return `${location.pathname}?${params.toString()}`;
    }

    // 検索フォームの入力イベントハンドラー
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        switch (e.currentTarget.name) {
            case "name":
                setName(e.currentTarget.value);
                break;
            case "division":
                setDivision(e.currentTarget.value);
                break;
        }
    };

    // CSVダウンロード
    const onClickCsv = (e: React.MouseEvent<HTMLSpanElement>): void => {
        e.preventDefault();
        // Axios経由でダウンロードすると、なぜかBOMが消えるので直接遷移する。
        window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/mimosys/csv?accessToken=${token}`;
    };

    const start = (page - 1) * limit;
    const end = start + limit;
    const pages = total / limit;

    const nextDirection = direction === "asc" ? "desc" : "asc";

    return <Layout>

        <StyledList>

            <Message message={message}/>

            <div className="header">

                <form className="search" onSubmit={onPaginationSubmit}>
                    <BaseInput name="name" value={name} onChange={onChange} placeholder="氏名"/>
                    <BaseInput name="division" value={division} onChange={onChange} placeholder="部署"/>
                    <button>検索</button>
                </form>

                <span className="btn-download" onClick={onClickCsv}>CSVダウンロード</span>

            </div>

            <table>
                <thead>
                <tr>
                    <th>名前</th>
                    <th>部署</th>
                    <th><NavLink to={createPageURL(page, limit, "last_analyzed", nextDirection)}>最終分析日時</NavLink></th>
                    <th><NavLink to={createPageURL(page, limit, "last_vitality", nextDirection)}>元気圧<span>（最新）</span></NavLink></th>
                    <th><NavLink to={createPageURL(page, limit, "last_vitality_comparison", nextDirection)}>元気圧<span>（前回比）</span></NavLink></th>
                    <th><NavLink to={createPageURL(page, limit, "last_mental_activity", nextDirection)}>心の活量値<span>（最新）</span></NavLink></th>
                    <th><NavLink to={createPageURL(page, limit, "mental_activity_trends", nextDirection)}>心の活量値<span>（2週間傾向）</span></NavLink></th>
                </tr>
                </thead>

                <tbody>

                {mimosysTrends.map((d, i) => {

                    // 記号
                    let symbol = "";
                    if (d.last_vitality_comparison > 0) {
                        // プラスの場合だけ表示（マイナスは元々ついてる。）
                        symbol = "+";
                    }

                    return <tr key={`mimosys-trend-${i}`}>
                        <td><NavLink to={`/graph?name=${d.employee_name}`}>{d.employee_name}</NavLink></td>
                        <td><NavLink to={`/graph?division=${d.division_name}`}>{d.division_name}</NavLink></td>
                        <td>{d.last_analyzed}</td>
                        <td>{convertValue(d.last_vitality)}</td>
                        <td>{symbol}{convertValue(d.last_vitality_comparison)}</td>
                        <td>{convertValue(d.last_mental_activity)}</td>
                        <td className="trend">
                            {d.num_vitality_data <= 4 && <span>[</span>}
                            {d.mental_activity_trends > 0 && <img src={icoMimosysUp} alt="上昇傾向"/>}
                            {d.mental_activity_trends === 0 && <img src={icoMimosysNormal} alt="通常通り"/>}
                            {d.mental_activity_trends < 0 && <img src={icoMimosysDown} alt="下降傾向"/>}
                            {d.num_vitality_data <= 4 && <span>]</span>}
                        </td>
                    </tr>;
                })}

                </tbody>

            </table>

            <StyledPagination onSubmit={onPaginationSubmit} method="get">
                <span>表示件数</span>
                <input type="number" value={inputLimit} onChange={onChangeLimit} min={1}/>
                <span>件</span>
                <span>{total}件中 {start + 1}-{end}件</span>
                {(page >= 2) && <NavLink to={createPageURL(page - 1, limit, sort, direction)}>前へ</NavLink>}
                {(page < pages) && <NavLink to={createPageURL(page + 1, limit, sort, direction)}>次へ</NavLink>}
            </StyledPagination>

        </StyledList>

    </Layout>

};

const StyledList = styled.div`

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .search {
      display: flex;

      input {
        margin-right: 20px;
      }

      button {
        border: none;
        height: 38px;
        color: #fff;
        background-color: #031F69;
        width: 105px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
        font-size: 16px;
        border-radius: 2px;
      }
    }

    .btn-download {
      line-height: 38px;
      width: 212px;
      display: block;
      text-align: center;
      padding: 0 0 0 20px;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
      background-image: url(${icoDown});
      background-repeat: no-repeat;
      background-position: left 27px top 50%;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }
    }
  }

  table {
    width: 100%;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
    border-collapse: collapse;

    thead {
      th {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        background-color: #fff;
        padding: 10px;

        span {
          display: block;
          font-weight: 400;
          font-size: 13px;
        }

        a {
          display: block;
          color: #000;
          text-decoration: none;
          background-image: url(${icoSort});
          background-position: right 0 top 50%;
          background-repeat: no-repeat;
          padding-right: 20px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    tbody {
      tr {
        background-color: #fff;

        &:nth-child(2n + 1) {
          background-color: #EAEAEA;
        }

        td {
          padding: 10px;
          text-align: center;
          font-size: 15px;

          &.text-left {
            text-align: left;
          }
          
          &.trend {
            display: flex;
            align-items: center;
            justify-content: center;
            
            span {
              line-height: 1;
              font-size: 30px;
              color: #aaa;
              padding-bottom: 7px;
              font-weight: 500;
            }
            
            img {
              margin: 0 10px;
            }
          }

          a {
            color: #0072B9;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

      }
    }
  }

`;


// ページネーションのスタイル
const StyledPagination = styled.form`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  align-items: center;

  span {
    margin-right: 15px;
  }

  input {
    margin-right: 5px;
    width: 70px;
    border: 1px solid #ccc;
    padding: 5px 5px;
    text-align: right;
  }

  a {
    color: #0072B9;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

`;