import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Layout} from "./Layout";
import styled from "styled-components";
import {SetPageTitle} from "../../utility/Utility";
import {NotificationModal} from "./NotificationModal";
import {BaseCheckbox} from "../common/BaseCheckbox";
import {AppContext} from "../../context/AppContext";
import {ApiMimosysNotification, ApiToggleMimosysNotification} from "../../api/Api";
import {Message} from "../common/Message";
import {MimosysNotificationData, NotificationGetResponse, NotificationToggleRequest} from "../../typings";

export const Setting = () => {

    const {setSpinner} = useContext(AppContext);
    const [isModal, setIsModal] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [notificationSettings, setNotificationSettings] = useState<{ [key: string]: MimosysNotificationData } | null>(null);
    const [notificationType, setNotificationType] = useState<string>("");

    document.title = SetPageTitle("設定");

    useEffect(() => {
        getNotificationSetting();
    }, []);

    // 通知設定を取得
    const getNotificationSetting = () => {
        setSpinner(true);

        ApiMimosysNotification()
            .then((res) => {
                const data = res.data as NotificationGetResponse;
                setNotificationSettings(data.notifications);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }
            })
            .finally(() => {
                setSpinner(false);
            });
    };

    // モーダルを開く
    const onModal = (e: React.MouseEvent<HTMLButtonElement>, notificationType: string) => {
        e.preventDefault();
        setIsModal(!isModal);
        setNotificationType(notificationType);
    };

    // モーダルを閉じる
    const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsModal(false);
        setNotificationType("");
    };

    // チェックボックスの切り替え
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const name = e.currentTarget.name;

        if (!notificationSettings) {
            return;
        }

        const j = JSON.stringify(notificationSettings);
        const newSetting = JSON.parse(j) as { [key: string]: MimosysNotificationData };
        newSetting[name].active = !newSetting[name].active;
        setNotificationSettings(newSetting);

        // 切り替え（保存ボタンがないので、即時に切り替え）
        toggleActive(newSetting[name].id, newSetting[name].active);
    };

    // 有効・無効の切り替え
    const toggleActive = (id: number, active: boolean): void => {

        const req: NotificationToggleRequest = {
            id: id,
            active: active,
        };

        ApiToggleMimosysNotification(req)
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }
            })
            .finally(() => {
            });
    };

    // 保存完了
    const onPostComplete = () => {
        getNotificationSetting();
    };

    if (!notificationSettings) {
        // 読み込み中
        return <Layout/>
    }

    return <Layout>

        <Message message={message}/>

        <StyledSetting>

            <h2>通知設定</h2>

            <p>※通知設定にチェックしてください。</p>


            <div className="notifications">

                <div className="notification">
                    <label>
                        <BaseCheckbox type="checkbox" name="lower_person" checked={notificationSettings["lower_person"].active} onChange={onChange}/>
                        下降傾向の人物
                    </label>
                    <button type="button" onClick={(e) => onModal(e, "lower_person")}>対象選択</button>
                </div>

                <div className="notification">
                    <label>
                        <BaseCheckbox type="checkbox" name="lower_division" checked={notificationSettings["lower_division"].active} onChange={onChange}/>
                        下降傾向の部署
                    </label>
                    <button type="button" onClick={(e) => onModal(e, "lower_division")}>対象選択</button>
                </div>

                <div className="notification">
                    <label>
                        <BaseCheckbox type="checkbox" name="upper_person" checked={notificationSettings["upper_person"].active} onChange={onChange}/>
                        上昇傾向の人物
                    </label>
                    <button type="button" onClick={(e) => onModal(e, "upper_person")}>対象選択</button>
                </div>

                <div className="notification">
                    <label>
                        <BaseCheckbox type="checkbox" name="upper_division" checked={notificationSettings["upper_division"].active} onChange={onChange}/>
                        上昇傾向の部署
                    </label>
                    <button type="button" onClick={(e) => onModal(e, "upper_division")}>対象選択</button>
                </div>

            </div>

        </StyledSetting>

        {isModal && <NotificationModal
            onComplete={onPostComplete}
            onClose={onModalClose}
            notificationSetting={notificationSettings[notificationType]}
        />}

    </Layout>
};

const StyledSetting = styled.div`
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
  border-radius: 2px;

  h2 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .notifications {
    margin-bottom: 30px;

    .notification {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      button {
        margin-left: 20px;
        background-color: #092267;
        color: #fff;
        border: none;
        border-radius: 2px;
        line-height: 22px;
        width: 77px;
        text-align: center;
        font-size: 12px;
        cursor: pointer;

        &:hover {
          opacity: .7;
        }
      }

    }
  }


`;