import {Layout} from "./Layout";
import styled from "styled-components";
import {CreateMd, SetPageTitle} from "../../utility/Utility";
import img1 from "../../images/dashboard-1.svg";
import img2 from "../../images/dashboard-2.svg";
import img3 from "../../images/dashboard-3.svg";
import {NavLink} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {ApiDashboard} from "../../api/Api";
import {AppContext} from "../../context/AppContext";
import {Message} from "../common/Message";
import {DashboardResponse} from "../../typings";

export const Dashboard = () => {

    const {setSpinner} = useContext(AppContext);
    const [message, setMessage] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [dashboardData, setDashboardData] = useState<DashboardResponse | null>(null);

    document.title = SetPageTitle("ダッシュボード");

    useEffect(() => {
        getDashboardData();
    }, []);

    // ダッシュボードのデータを取得
    const getDashboardData = () => {

        setSpinner(true);

        ApiDashboard()
            .then((res) => {

                const data = res.data as DashboardResponse;

                setDashboardData(data);
                setIsSuccess(true);

            })
            .catch((err) => {
                console.log(err);
                if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }
                setIsSuccess(false);
            })
            .finally(() => {
                setSpinner(false);
            });
    };

    const today = CreateMd(new Date());

    if (!isSuccess || !dashboardData) {
        return <Layout>
            <Message message={message}/>
        </Layout>
    }

    return <Layout>

        <StyledDashboard>

            <div className="cards">
                <div className="card">
                    <span>登録従業員数</span>
                    <div className="number">{dashboardData.employee_num}</div>
                </div>
                <div className="card">
                    <span>本日（{today}）の計測数</span>
                    <div className="number">{dashboardData.analyzed_employee_num}</div>
                </div>
                <div className="card">
                    <span>本日（{today}）の計測率</span>
                    <div className="number">{dashboardData.analyzed_percentage.toFixed(1)}<span>%</span></div>
                </div>
            </div>

            <div className="trend-tables">

                <table>
                    <thead>
                    <tr>
                        <th colSpan={4}>下降傾向の人物</th>
                    </tr>
                    </thead>
                    <tbody>

                    {dashboardData.descent_employees.length === 0 && <tr>
                        <td colSpan={4} className="no-record">データはありませんでした</td>
                    </tr>}

                    {dashboardData.descent_employees.map((d, i) => {
                        return <tr key={`descent_employees-${i}`}>
                            <td>{d.is_new && <span className="new">NEW</span>}</td>
                            <td>{d.last_analyzed}</td>
                            <td>{d.label}</td>
                            <td><NavLink to={`/graph?name=${encodeURIComponent(d.label)}`}>推移を見る</NavLink></td>
                        </tr>
                    })}

                    </tbody>
                </table>

                <table>
                    <thead>
                    <tr>
                        <th colSpan={4}>下降傾向の部署</th>
                    </tr>
                    </thead>
                    <tbody>

                    {dashboardData.descent_divisions.length === 0 && <tr>
                        <td colSpan={4} className="no-record">データはありませんでした</td>
                    </tr>}

                    {dashboardData.descent_divisions.map((d, i) => {
                        return <tr key={`descent_divisions-${i}`}>
                            <td>{d.is_new && <span className="new">NEW</span>}</td>
                            <td>{d.last_analyzed}</td>
                            <td>{d.label}</td>
                            <td><NavLink to={`/graph?division=${encodeURIComponent(d.label)}`}>推移を見る</NavLink></td>
                        </tr>
                    })}

                    </tbody>
                </table>

            </div>


            <div className="trend-tables">

                <table>
                    <thead>
                    <tr>
                        <th colSpan={4}>上昇傾向の人物</th>
                    </tr>
                    </thead>
                    <tbody>

                    {dashboardData.ascend_employees.length === 0 && <tr>
                        <td colSpan={4} className="no-record">データはありませんでした</td>
                    </tr>}

                    {dashboardData.ascend_employees.map((d, i) => {
                        return <tr key={`ascend_employees-${i}`}>
                            <td>{d.is_new && <span className="new">NEW</span>}</td>
                            <td>{d.last_analyzed}</td>
                            <td>{d.label}</td>
                            <td><NavLink to={`/graph?name=${encodeURIComponent(d.label)}`}>推移を見る</NavLink></td>
                        </tr>
                    })}

                    </tbody>
                </table>

                <table>
                    <thead>
                    <tr>
                        <th colSpan={4}>上昇傾向の部署</th>
                    </tr>
                    </thead>
                    <tbody>

                    {dashboardData.ascend_divisions.length === 0 && <tr>
                        <td colSpan={4} className="no-record">データはありませんでした</td>
                    </tr>}

                    {dashboardData.ascend_divisions.map((d, i) => {
                        return <tr key={`ascend_divisions-${i}`}>
                            <td>{d.is_new && <span className="new">NEW</span>}</td>
                            <td>{d.last_analyzed}</td>
                            <td>{d.label}</td>
                            <td><NavLink to={`/graph?division=${encodeURIComponent(d.label)}`}>推移を見る</NavLink></td>
                        </tr>
                    })}

                    </tbody>
                </table>

            </div>

        </StyledDashboard>

    </Layout>
};

const StyledDashboard = styled.div`

  // 統計カード
  .cards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .card {
      width: 31%;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 30px 15px 30px 63px;
      background-repeat: no-repeat;
      background-position: left 10px top 50%;
      background-size: 45px auto;
      border-radius: 2px;

      > span {
        font-size: 16px;
        margin-right: 30px;
        white-space: nowrap;
      }

      .number {
        font-size: 36px;
        font-weight: bold;

        span {
          font-size: 15px;
          font-weight: bold;
          display: inline-block;
          margin-left: 4px;
        }
      }

      &:nth-child(1) {
        background-image: url(${img1});
      }

      &:nth-child(2) {
        background-image: url(${img2});
      }

      &:nth-child(3) {
        background-image: url(${img3});
        
        > span {
          margin-right: 20px;
        }
      }

    }
  }

  // 傾向リスト
  .trend-tables {
    display: flex;
    margin-bottom: 40px;
    align-items: flex-start;
    justify-content: space-between;

    table {
      width: 48%;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
      border-collapse: collapse;

      thead {
        th {
          font-weight: 500;
          font-size: 16px;
          text-align: left;
          padding: 10px;
          background-color: #fff;

        }
      }

      tbody {
        td {
          text-align: center;
          padding: 10px;
          font-size: 16px;

          .new {
            color: #ff0000;
            font-size: 12px;
          }

          a {
            color: #0072B9;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          &.no-record {
            padding: 20px;
            color: #aaa;
          }

        }
      }
    }
  }
`;
