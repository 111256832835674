// ページタイトルを設定
export const SetPageTitle = (str: string): string => {
    return `${str} | TRIBAWL Health Care System`;
};

// Data型をYY/mmに変換
export const CreateMd = (dt: Date): string => {
    return `${dt.getMonth() + 1}/${dt.getDate()}`;
};

// YYYY-MM-DD HH:MM:SSをHH:MMに変換
export const CreateTime = (dt: string): string => {
    const parts = dt.split(" ");
    const timeParts = parts[1].split(":");

    return `${timeParts[0]}:${timeParts[1]}`;
};

// YYYY-MM-DD HH:MM:SSをYYYY-MM-DDに変換
export const CreateYmd = (dt: string): string => {
    const parts = dt.split(" ");
    return parts[0];
};

// Date型をYYYY-MM-DDに変換
export const CreateYmdFromDate = (dt: Date): string => {
    return `${dt.getFullYear()}-${Zerofill(dt.getMonth() + 1, 2)}-${Zerofill(dt.getDate(), 2)}`;
};

// Date型をYYYY-MMに変換
export const CreateYmFromDate = (dt: Date): string => {
    return `${dt.getFullYear()}-${Zerofill(dt.getMonth() + 1, 2)}`;
};

// Date型を渡して翌月のDate型を返す
export const GetNextMonth = (dt: Date): Date => {
    return new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
};

// Date型を渡して前月のDate型を返す
export const GetPrevMonth = (dt: Date): Date => {
    return new Date(dt.getFullYear(), dt.getMonth() - 1, 1);
};

// YYYY-MM-DDをDateに変換
export const CreateDateFromYmd = (ymd: string): Date => {
    try {
        const parts = ymd.split("-");
        return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
    } catch (e) {
        // エラー時はとりあえず、現在時刻のDateを作成
        console.log(e);
        return new Date();
    }
};

// 数値のゼロパディング(とりあえず、必要な桁数のみ対応)
export const Zerofill = (i: number, digits: number): string => {
    switch (digits) {
        case 2:
            if (i < 10) {
                return `0${i}`;
            }
            break;
        case 3:
            if (i < 10) {
                return `00${i}`;
            }
            if (i < 100) {
                return `0${i}`;
            }
            break;
    }
    return `${i}`;
}

export const ConfirmationMethods: { [key: string]: string } = {
    "video": "ビデオ通話",
    "phone": "電話",
    "meet": "対面",
    "other": "その他",
};

// convertValue はfloat64なデータを12.3に丸める
export const convertValue = (floatValue: number): number => {
    return Math.floor(floatValue * 1000) / 10;
};