import * as React from "react";
import {useContext, useState} from "react";
import {StyledLoginForm} from "./StyledLoginForm";
import {ResetRequest} from "../../typings";
import {AppContext} from "../../context/AppContext";
import {ApiReset} from "../../api/Api";
import {FormError} from "../common/FormError";
import {Message} from "../common/Message";
import {NavLink} from "react-router-dom";
import {CommonApiResponse} from "../../../reception-service/services/react-common/typings";
import {SetPageTitle} from "../../utility/Utility";

export const Reset = () => {

    const [password, setPassword] = useState<string>("");
    const [confirmation_password, setConfirmationPassword] = useState<string>("");
    const {setSpinner} = useContext(AppContext);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string|null>(null);

    document.title =  SetPageTitle("パスワード設定");

    // フォーム変更ハンドラー
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        e.preventDefault();

        switch (e.currentTarget.name) {
            case "password":
                setPassword(e.currentTarget.value);
                break;
            case "confirmation_password":
                setConfirmationPassword(e.currentTarget.value);
                break;
        }
    };

    // フォーム送信ハンドラー
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const params = new URLSearchParams(window.location.search);

        const req: ResetRequest = {
            reset_token: params.get("token") ?? "",
            password: password,
            confirmation_password: confirmation_password,
        }

        setErrors({});
        setMessage(null);

        setSpinner(true);

        ApiReset(req)
            .then((res) => {

                const data = res.data as CommonApiResponse;
                console.log(data);
                setIsSuccess(true);
            })
            .catch((err) => {
                console.log(err.response);

                if (err.response.status === 406) {
                    // バリデーションエラーの場合
                    const keys = Object.keys(err.response.data);
                    const _tmp: { [key: string]: string } = {};
                    keys.forEach((k) => {
                        _tmp[k] = err.response.data[k];
                    });
                    setErrors(_tmp);
                } else if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }

            })
            .finally(() => {
                setSpinner(false);
            });
    };

    return <StyledLoginForm>

        <h1>TRIBAWL</h1>

        <Message message={message}/>

        {isSuccess && <React.Fragment>
            <div className="sent">パスワードを設定完了しました。</div>
            <div className="to-login"><NavLink to={`/login`}>ログイン</NavLink></div>
        </React.Fragment>}

        {!isSuccess &&

            <form onSubmit={onSubmit}>

                <h2>新しいパスワード設定</h2>

                <p className="center">
                    新しいパスワードを入力してください。<br/>
                    ※8文字以上の半角英数字・記号のみ
                </p>

                <div>
                    <input type="password" name="password" placeholder="新しいパスワード" value={password} onChange={onChange}/>
                    <FormError message={errors["password"]}/>
                </div>
                <div>
                    <input type="password" name="confirmation_password" placeholder="新しいパスワード（再入力）" value={confirmation_password} onChange={onChange}/>
                    <FormError message={errors["confirmation_password"]}/>
                </div>
                <button>送信</button>

            </form>

        }

    </StyledLoginForm>
};