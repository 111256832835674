import styled from "styled-components";
import checkOff from "../../images/chck-off.svg";
import checkOn from "../../images/check-on.svg";

export const BaseCheckbox = styled.input`
  appearance: none;
  width: 15px;
  height: 15px;
  border: none;
  background-image: url(${checkOff});
  margin-right: 7px;

  &:checked {
    background-image: url(${checkOn});
  }
`;