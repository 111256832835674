import * as React from "react";
import {useState} from "react";
import styled from "styled-components";

export interface GraphData {
    ymd: string;
    values: number[];
}

export interface GraphMetaData {
    employee_id: string;
    label: string;
    data: GraphData[];
}

interface Props {
    data: GraphMetaData[];
}

export interface HoverContent {
    ymd: string;
    label: string;
    value: number;
    left: number;
    top: number;
}

export const MimosysGraph = (props: Props) => {

    const [selectOffset, setSelectOffset] = useState<number | null>(null);
    const [hoverContents, setHoverContents] = useState<HoverContent | null>(null);

    // 選択・解除
    const onSelect = (offset: number): void => {
        if (selectOffset === offset) {
            // 解除時
            setSelectOffset(null);
        } else {
            // 選択時
            setSelectOffset(offset);
        }
    }

    // サークルホバー
    const onHover = (e: React.MouseEvent<SVGCircleElement>, label: string, graphData: GraphData, offset: number): void => {

        const rect = e.currentTarget.getBoundingClientRect();

        setHoverContents({
            label: label,
            ymd: graphData.ymd,
            value: graphData.values[offset] ? graphData.values[offset] : 0,
            left: rect.left - 60,
            top: (rect.top + window.scrollY) - 80,
        });
    };

    // サークルホバー終了
    const onHoverOut = () => {
        setHoverContents(null);
    };

    const stageWidth = 1000;
    const stageHeight = 563;

    const mainWidth = stageWidth * .9;
    const mainHeight = stageHeight * .85;

    const dataNum = props.data.length;
    const xNum = props.data[0].data.length;
    const stepX = mainWidth / xNum;
    const stepY = mainHeight / 10;

    // 左側凡例
    const leftLegends: JSX.Element[] = [];
    let a = 0;
    for (let i = 100; i >= 0; i -= 10) {
        const y = a * stepY;
        leftLegends.push(<text key={`right-${i}`} x={20} y={y}>{i}</text>);
        a++;
    }

    // 間引き量
    const maxLabels = 31;
    const bottomStep = Math.ceil(xNum / maxLabels);

    // 下側凡例
    const bottomLegends: JSX.Element[] = [];
    a = 0;
    for (let i = 0; i < xNum; i++) {

        if (i % bottomStep !== 0) {
            // 適当に間引く
            a++;
            continue;
        }

        const x = a * stepX;
        bottomLegends.push(
            <text key={`bottom-${i}`} x={x} y={0} transform={`rotate(-45 ${x} 0)`}>{props.data[0].data[i].ymd}</text>
        );
        a++;
    }

    // メインエリアのライン
    const mainLines: JSX.Element[] = [];
    for (let i = 0; i <= 10; i++) {
        const y = i * stepY;
        mainLines.push(<line key={`line-${i}`} x1={0} y1={y} x2={mainWidth} y2={y} strokeWidth={1} stroke="#ddd"/>);
        a++;
    }

    // 色定義
    const colors = [
        "#13509B",
        "#C03B3B",
        "#0F6F17",
        "#F8B92B",
        "#FE8923",
        "#6F6F6F",
    ];

    // データのライン
    const dataLines: JSX.Element[] = [];
    const dataCircles: JSX.Element[] = [];
    for (let i = 0; i < dataNum; i++) {
        const paths: string[] = [];

        let opacity = 1;
        if (selectOffset !== null && selectOffset !== i) {
            // 選択されている場合、選択されていないデータを薄く表示
            opacity = 0.1;
        }

        const color = colors[i % colors.length];

        // パス、サークルを描画
        props.data[i].data.forEach((d, k) => {

            for (let a = 0; a <= 1; a++) {

                if (!d.values[a]) {
                    // 値がない場合はスキップ
                    return;
                }

                let x = k * stepX;

                if(a === 1) {
                    // 2つめのデータ（通常退勤データ）は半分ずらす
                    x += stepX / 2;
                }

                const y = mainHeight * ((100 - d.values[a]) / 100);
                dataCircles.push(<circle
                    onMouseOver={(e) => onHover(e, props.data[i].label, d, a)}
                    onMouseOut={onHoverOut}
                    key={`circle-${i}-${k}-${a}`} cx={x} cy={y} r={4}
                    fill="#fff"
                    strokeWidth={2}
                    stroke={color}
                    opacity={opacity}
                />);

                if (paths.length === 0) {
                    paths.push(`M${x} ${y}`);
                } else {
                    paths.push(`L${x} ${y}`);
                }

            }

        });

        dataLines.push(<path key={`data-line-${i}`} d={paths.join(" ")} strokeWidth={2} stroke={color} fill="none" opacity={opacity}/>)
    }

    return <StyledMimosysGraph>

        <svg width={stageWidth} height={stageHeight} viewBox={`0 0 ${stageWidth} ${stageHeight}`}>

            <g transform={`translate(0 20)`}>
                <g className="left" transform={`translate(30 0)`}>
                    {leftLegends}
                </g>
                <g className="lines" transform={`translate(60 0)`}>
                    {mainLines}
                </g>
                <g className="bottom" transform={`translate(60 ${mainHeight + 10})`}>
                    {bottomLegends}
                </g>

                <g className="data" transform={`translate(60 0)`}>
                    {dataLines}
                    {dataCircles}
                </g>
            </g>

        </svg>

        <div className="legends">
            {props.data.map((d, i) => {
                return <div key={`legend-${i}`} className="legend" onClick={() => onSelect(i)}>
                    <span style={{backgroundColor: colors[i]}}/>
                    {d.label}
                </div>
            })}
        </div>

        {hoverContents && <div className="hover" style={{left: `${hoverContents.left + 10}px`, top: `${hoverContents.top + 10}px`}}>
            {hoverContents.label}<br/>
            {hoverContents.ymd}<br/>
            {hoverContents.value}<br/>
        </div>}

    </StyledMimosysGraph>

};

const StyledMimosysGraph = styled.div`
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
  border-radius: 2px;
  padding: 20px;
  //max-width: 1200px;
  display: block;
  margin: auto;
  width: 100%;

  svg {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;

    .left {
      text {
        font-size: 10px;
        text-anchor: end;
        dominant-baseline: middle;
      }
    }

    .bottom {
      text {
        font-size: 10px;
        text-anchor: end;
        dominant-baseline: middle;
      }
    }
  }

  .legends {
    display: flex;
    justify-content: center;

    .legend {
      font-size: 12px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        text-decoration: underline;
      }

      span {
        display: block;
        height: 3px;
        width: 20px;
        margin-right: 10px;
      }
    }
  }

  .hover {
    background-color: rgba(0, 0, 0, .7);
    color: #fff;
    font-size: 12px;
    padding: 5px 15px;
    position: absolute;
    border-radius: 4px;
  }

`;
