import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {StyledLoginForm} from "./StyledLoginForm";
import {LoginRequest, LoginResponse, SsoRequest} from "../../typings";
import {AppContext} from "../../context/AppContext";
import {ApiLogin, ApiSSO} from "../../api/Api";
import {FormError} from "../common/FormError";
import {Message} from "../common/Message";
import {Navigate, NavLink} from "react-router-dom";
import {SetPageTitle} from "../../utility/Utility";

export const Login = () => {

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const {setSpinner, setToken, setCompanyAccount, companyAccount} = useContext(AppContext);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string|null>(null);

    let isSSOLoading = false;

    document.title =  SetPageTitle("ログイン");

    useEffect(() => {
        if (companyAccount) {
            setIsSuccess(true);
        }

        const params = new URLSearchParams(window.location.search);
        const onetimePassword = params.get("sso");

        if (onetimePassword) {
            // ワンタイムパスワードでログイン
            trySSO(onetimePassword);
            isSSOLoading = true;
        }

    }, []);

    // SSOログインを試みる
    const trySSO = (onetimePassword: string) => {

        if (isSSOLoading) {
            // 読み込み中は無視
            // ローカル開発中、useEffectは二回実行される。
            return;
        }

        setSpinner(true);

        const req: SsoRequest = {
            onetime_password: onetimePassword,
        };

        ApiSSO(req)
            .then((res) => {

                const data = res.data as LoginResponse;
                console.log(data);

                setCompanyAccount(data.company_account);
                setToken(data.token);
                setIsSuccess(true);

            })
            .catch((err) => {
                // エラーの場合は黙殺して、そのままログイン画面させる。
                console.log(err);
            })
            .finally(() => {
                setSpinner(false);
            });
    };

    // フォーム変更ハンドラー
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        e.preventDefault();

        switch (e.currentTarget.name) {
            case "email":
                setEmail(e.currentTarget.value);
                break;
            case "password":
                setPassword(e.currentTarget.value);
                break;
        }
    };

    // フォーム送信ハンドラー
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: LoginRequest = {
            email: email,
            password: password,
            site: "mimosys",
        }

        setErrors({});
        setMessage(null);

        setSpinner(true);

        ApiLogin(req)
            .then((res) => {

                const data = res.data as LoginResponse;

                setCompanyAccount(data.company_account);
                setToken(data.token);
                setIsSuccess(true);

            })
            .catch((err) => {
                console.log(err.response);

                if (err.response.status === 406) {
                    // バリデーションエラーの場合
                    console.log(err.response.data);

                    const keys = Object.keys(err.response.data);

                    const _tmp: { [key: string]: string } = {};

                    keys.forEach((k) => {
                        _tmp[k] = err.response.data[k];
                    });

                    setErrors(_tmp);
                } else if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }

            })
            .finally(() => {
                setSpinner(false);
            });
    };

    if (isSuccess) {
        return <Navigate to={`/`}/>
    }

    return <StyledLoginForm>

        <h1>TRIBAWL</h1>

        <Message message={message}/>

        <form onSubmit={onSubmit}>
            <div>
                <input type="email" name="email" placeholder="ID" value={email} onChange={onChange}/>
                <FormError message={errors["email"]}/>
            </div>
            <div>
                <input type="password" name="password" placeholder="パスワード" value={password} onChange={onChange}/>
                <FormError message={errors["password"]}/>
            </div>

            <button>ログイン</button>

            <div className="forgot">
                <NavLink to={`/forgot`}>パスワードを忘れた方はこちら</NavLink>
            </div>

        </form>

    </StyledLoginForm>
};