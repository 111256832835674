import axios, {AxiosPromise} from "axios";
import {ForgotRequest, LoginRequest, MimosysTrendPagingRequest, NotificationPostRequest, NotificationToggleRequest, ResetRequest, SsoRequest} from "../typings";
import {MimosysReportRequest} from "../../reception-service/services/react-common/typings";

// 名前の重複をさけるため、各定数の先頭に "Api" をつける

// 共通のリクエストインスタンス
const AxiosInterface = axios.create();
AxiosInterface.interceptors.request.use((config) => {
    const token: string = localStorage.getItem("alcohol_token") ? localStorage.getItem("alcohol_token") as string : "";
    config.headers = {
        "Authorization": `Bearer ${token}`,
    };
    return config;
});

// ドメイン名を連結して返す
const createEndpointUrl = (path: string): string => {
    return `${process.env.REACT_APP_API_ENDPOINT}${path}`;
}

// ログイン
export const ApiSSO = (req: SsoRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/company-account/sso`), req);
};

// ログイン
export const ApiLogin = (req: LoginRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/company-account/login`), req);
};

// ログアウト
export const ApiLogout = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/company-account/logout`));
};

// パスワード再発行メール送信
export const ApiForgot = (req: ForgotRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/company-account/forgot`), req);
};

// パスワード再設定
export const ApiReset = (req: ResetRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/company-account/reset`), req);
};

// 自分の情報取得
export const ApiMe = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/company-account/me`));
};

// 通知設定の取得
export const ApiMimosysNotification = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/mimosys-notification`));
};

// 通知設定切り替え
export const ApiToggleMimosysNotification = (req: NotificationToggleRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/mimosys-notification/toggle`), req);
};

// 通知設定切り替え
export const ApiPostMimosysNotification = (req: NotificationPostRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/mimosys-notification`), req);
};

// 通知設定切り替え
export const ApiListCompanyAccounts = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/company-account/list`));
};

// ダッシュボードトップ用API
export const ApiDashboard = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/mimosys/dashboard`));
};

// MIMOSYS傾向ページング
export const ApiPagingMimosysTrends = (req: MimosysTrendPagingRequest): AxiosPromise<any> => {
    const params = new URLSearchParams();
    params.set("page", String(req.page));
    params.set("limit", String(req.limit));
    params.set("name", req.name);
    params.set("division", req.division);
    params.set("sort", req.sort);
    params.set("direction", req.direction);
    return AxiosInterface.get(createEndpointUrl(`/mimosys/paging?${params.toString()}`));
};

// MIMOSYSグラフデータ
export const ApiGraphData = (req: MimosysReportRequest): AxiosPromise<any> => {
    const params = new URLSearchParams();
    params.set("from", req.from);
    params.set("to", req.to);
    params.set("name", req.name ?? "");
    params.set("division", req.division ?? "");
    return AxiosInterface.get(createEndpointUrl(`/mimosys/graph?${params.toString()}`));
};