import * as React from "react";
import {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {BaseCheckbox} from "../common/BaseCheckbox";
import {ApiListCompanyAccounts, ApiPostMimosysNotification} from "../../api/Api";
import {Message} from "../common/Message";
import {SuccessMessage} from "../common/SuccessMessage";
import {AppContext} from "../../context/AppContext";
import {CompanyAccountListResponse, MimosysNotificationData, NotificationPostRequest} from "../../typings";
import icoPlus from "../../images/ico-plus.svg";
import {FormError} from "../common/FormError";

interface Props {
    onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
    notificationSetting: MimosysNotificationData;
    onComplete: () => void;
}

export const NotificationModal = (props: Props) => {

    const {setSpinner} = useContext(AppContext);
    const [message, setMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [companyAccounts, setCompanyAccounts] = useState<{ [key: string]: string } | null>(null);
    const [selectedCompanyAccounts, setSelectedCompanyAccounts] = useState<string[]>([]);
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
    const [inputEmail, setInputEmail] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {

        // 企業アカウントを読み込み
        getCompanyAccounts();

        setSelectedCompanyAccounts(props.notificationSetting.company_account_ids);
        setSelectedEmails(props.notificationSetting.emails);

    }, []);

    // 企業アカウントのリストを取得
    const getCompanyAccounts = () => {

        setSpinner(true);

        ApiListCompanyAccounts()
            .then((res) => {
                const data = res.data as CompanyAccountListResponse;
                setCompanyAccounts(data.company_accounts);
                setMessage(null);
            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }
            })
            .finally(() => {
                setSpinner(false);
            });

    };

    // 保存
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSpinner(true);
        setErrors({});
        setSuccessMessage(null);
        setMessage(null);

        const req: NotificationPostRequest = {
            id: props.notificationSetting.id,
            company_account_ids: selectedCompanyAccounts,
            emails: selectedEmails,
        };

        ApiPostMimosysNotification(req)
            .then((res) => {
                console.log(res.data);

                setSuccessMessage("送信先を保存しました。");

                props.onComplete();

            })
            .catch((err) => {
                if (err.response.status === 406) {
                    // バリデーションエラーの場合
                    console.log(err.response.data);

                    const keys = Object.keys(err.response.data);

                    const _tmp: { [key: string]: string } = {};

                    keys.forEach((k) => {
                        _tmp[k] = err.response.data[k];
                    });

                    setErrors(_tmp);
                } else if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }
            })
            .finally(() => {
                setSpinner(false);
                setErrors({});
            });

    };

    // 企業アカウントの選択トグル
    const onChangeCompanyAccount = (e: React.ChangeEvent<HTMLInputElement>): void => {

        let next = [...selectedCompanyAccounts];
        const currentIndex = next.indexOf(e.currentTarget.value);

        if (currentIndex === -1) {
            // なければ追加
            next.push(e.currentTarget.value);
        } else {
            // あれば削除
            next.splice(currentIndex, 1);
        }

        setSelectedCompanyAccounts(next);
    };

    // 追加メールアドレスの入力イベント
    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInputEmail(e.currentTarget.value);
    };

    // メールアドレス追加
    const onAddEmail = (): void => {

        if (!inputEmail) {
            alert("メールアドレスを入力してください。");
            return;
        }

        if (!/^[a-zA-Z\d_.+-]+@([a-zA-Z\d][a-zA-Z\d-]*[a-zA-Z\d]*\.)+[a-zA-Z]{2,}$/.test(inputEmail)) {
            alert("メールアドレスを正しく入力してください。");
            return;
        }

        const newEmails = [...selectedEmails];
        newEmails.push(inputEmail);
        setSelectedEmails(newEmails);
        setInputEmail("");
    };

    // メールアドレスを削除
    const onDeleteEmail = (e: React.MouseEvent<HTMLSpanElement>, email: string, offset: number) => {

        e.preventDefault();

        if (!window.confirm(`${email}を削除します。よろしいですか？`)) {
            return false;
        }

        const next = [...selectedEmails];
        next.splice(offset, 1);
        setSelectedEmails(next);
    };

    if (!companyAccounts) {
        return null;
    }

    const keys = Object.keys(companyAccounts);

    return <StyledNotificationModal>

        <div className="inner">
            <form onSubmit={onSubmit}>

                <Message message={message}/>
                <SuccessMessage message={successMessage}/>

                <p>※送信先アカウントをチェックしてください。</p>

                <div className="accounts">
                    {keys.map((key) => {
                        return <label key={`account-${key}`}>
                            <BaseCheckbox type="checkbox" value={key} checked={selectedCompanyAccounts.indexOf(key) !== -1} onChange={onChangeCompanyAccount}/>
                            {companyAccounts[key]}
                        </label>
                    })}
                </div>

                <p>※その他のメールアドレス</p>

                <div className="email-area">
                    <input type="email" value={inputEmail} onChange={onChangeEmail}/>
                    <img src={icoPlus} alt="追加" onClick={onAddEmail}/>

                    <div className="selected-email">
                        {selectedEmails.map((d, i) => {
                            return <div className="email">
                                <span key={`selected-emails-${i}`}>{d}</span>
                                <span className="btn-delete" onClick={(e) => onDeleteEmail(e, d, i)}>削除</span>
                            </div>
                        })}
                    </div>
                </div>

                <p>※追加・削除後、保存ボタンを押してください。</p>

                <FormError message={errors["id"]}/>
                <FormError message={errors["company_account_ids"]}/>
                <FormError message={errors["emails"]}/>

                <div className="footer">
                    <button type="button" className="btn-cancel" onClick={props.onClose}>閉じる</button>
                    <button type="submit" className="btn-save">保存</button>
                </div>

            </form>

        </div>

    </StyledNotificationModal>

};


const StyledNotificationModal = styled.div`
  background-color: rgba(0, 0, 0, .16);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;

  // モーダル実態
  .inner {
    width: 500px;
    overflow-y: scroll;
    max-height: 800px;
    background-color: #fff;
    padding: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      margin-bottom: 16px;
      font-size: 12px;
    }

    .accounts {
      margin-bottom: 30px;

      label {
        display: block;
        margin-bottom: 10px;
      }
    }

    // メールアドレス追加
    .email-area {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      flex-wrap: wrap;

      input[type=email] {
        height: 23px;
        width: 250px;
        border-radius: 2px;
        border: 1px solid #092267;
        margin-right: 20px;
        font-size: 14px;
        padding: 0 5px;
      }

      img {
        height: 23px;
        width: auto;
        cursor: pointer;
      }

      .selected-email {
        width: 100%;

        .email {
          display: flex;
          justify-content: flex-start;

          span {
            display: block;
            margin-top: 5px;
            font-size: 12px;
            margin-right: 10px;
            margin-bottom: 5px;
          }

          .btn-delete {
            cursor: pointer;
          }
        }
      }
    }

    // ボタンエリア
    .footer {
      display: flex;
      justify-content: flex-end;

      button {
        height: 30px;
        font-size: 15px;
        width: 90px;
        margin-right: 10px;
        border: none;
        border-radius: 2px;

        &:last-child {
          margin-right: 0;
        }

        &.btn-cancel {
          border: 1px solid #092267;
          color: #092267;
          background-color: #fff;
        }

        &.btn-save {
          background-color: #092267;
          color: #fff;
        }

        &:hover {
          opacity: .7;
        }
      }
    }

  }
`;