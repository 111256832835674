import styled from "styled-components";
import {useEffect, useState} from "react";

interface Props {
    message: string|null;
}

export const Message = (props: Props) => {

    const [isHide, setIsHide] = useState<boolean>(false);

    useEffect(() => {
        setIsHide(false);
    }, [props]);

    const onClose = () => {
        setIsHide(true);
    };

    if (!props.message || isHide) {
        return null;
    }


    return <StyledMessage>
        {props.message}
        <span onClick={onClose}>&times;</span>
    </StyledMessage>
};


const StyledMessage = styled.div`
  background-color: #ecc8c8;
  color: red;
  margin-bottom: 15px;
  border: 1px solid #cc0000;
  padding: 10px;
  border-radius: 4px;
  position: relative;
  
  span {
    display: block;
    line-height: 1;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
`
