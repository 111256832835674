import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {StyledLoginForm} from "./StyledLoginForm";
import {ForgotRequest} from "../../typings";
import {AppContext} from "../../context/AppContext";
import {ApiForgot} from "../../api/Api";
import {FormError} from "../common/FormError";
import {Message} from "../common/Message";
import {NavLink} from "react-router-dom";
import {CommonApiResponse} from "../../../reception-service/services/react-common/typings";
import {SetPageTitle} from "../../utility/Utility";

export const Forgot = () => {

    const [email, setEmail] = useState<string>("");
    const {setSpinner, companyAccount} = useContext(AppContext);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string|null>(null);

    document.title =  SetPageTitle("パスワードを忘れた方");

    useEffect(() => {
        if (companyAccount) {
            setIsSuccess(true);
        }
    }, []);

    // フォーム変更ハンドラー
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        e.preventDefault();

        switch (e.currentTarget.name) {
            case "email":
                setEmail(e.currentTarget.value);
                break;
        }
    };

    // フォーム送信ハンドラー
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: ForgotRequest = {
            email: email,
            site: "mimosys",
        }

        setErrors({});
        setMessage(null);
        setIsSuccess(false);

        setSpinner(true);

        ApiForgot(req)
            .then((res) => {

                const data = res.data as CommonApiResponse;

                console.log(data);
                setIsSuccess(true);
                setEmail("");
            })
            .catch((err) => {
                console.log(err.response);

                if (err.response.status === 406) {
                    // バリデーションエラーの場合
                    const keys = Object.keys(err.response.data);
                    const _tmp: { [key: string]: string } = {};
                    keys.forEach((k) => {
                        _tmp[k] = err.response.data[k];
                    });
                    setErrors(_tmp);
                } else if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }

            })
            .finally(() => {
                setSpinner(false);
            });
    };

    return <StyledLoginForm>

        <h1>TRIBAWL</h1>

        <Message message={message}/>

        {isSuccess && <div className="sent">メールを送信しました</div>}

        <form onSubmit={onSubmit}>

            <h2>パスワード再発行</h2>


            <p>ご登録済みのメールアドレスにパスワード再設定のリンクを送信します</p>

            <div>
                <input type="email" name="email" placeholder="メールアドレス" value={email} onChange={onChange}/>
                <FormError message={errors["email"]}/>
                <FormError message={errors["site"]}/>
            </div>
            <button>送信</button>


            {!isSuccess && <div className="cancel"><NavLink to={`/login`}>キャンセル</NavLink></div>}

        </form>

    </StyledLoginForm>
};