import {PropsWithChildren} from "react";
import {AuthCheck} from "../common/AuthCheck";
import {Header} from "./Header";
import styled from "styled-components";
import {Nav} from "./Nav";


export const Layout = ({children}: PropsWithChildren<any>) => {

    // 認証コンポーネントの子として追加
    return <AuthCheck>

        <StyledLayout>

            <Nav/>

            <main>

                <Header/>

                <div className="content">
                    {children}
                </div>

            </main>

        </StyledLayout>

    </AuthCheck>

};

const StyledLayout = styled.div`
  display: flex;
  min-height: 100vh;
  
  main {
    width: 100%;
    min-width: 1260px;
    flex: 1;
    
    .content {
      padding: 40px;
    }
  }
`;