import {useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {AppContext} from "../../context/AppContext";
import {ApiLogout} from "../../api/Api";

export const Logout = () => {

    const navigate = useNavigate();
    const {setCompanyAccount, setToken} = useContext(AppContext);

    useEffect(() => {
        sendLogout();
    }, []);

    const sendLogout = () => {

        ApiLogout()
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setCompanyAccount(null);
                setToken(null);
                navigate("/login");
            });
    };

    return null;
};